import React from "react";
import PromoList from "@/components/PromoList";

const PromoList3 = () => {
  return (
    <div>
    <PromoList cardNumer={3}/>
  </div>
  );
};

export default PromoList3;
